import {
  SET_USERINFO,
  CLEAR_USERINFO,
  SET_ROOMSTATUS,
  ADD_USER,
  REMOVE_USER,
  SET_LIVEINFO,
  SET_USERSTATUS,
  CLEAR_USER,
  SET_LIVERESULT,
  SAVE_PERMISSION,
} from './mutation-type';

export default {
  // 设置用户信息
  [SET_USERINFO](state, data) {
    // const res = JSON.parse(JSON.stringify(data));
    // res.uuid = `${data?.uuid}`;
    // res.userAccountId = data?.accountInfo.userAccountId;
    const { userAccountId, realName } = data?.accountInfo || { userAccountId: '', realName: '' };
    state.userInfo = {
      userAccountId,
      realName,
    };
  },
  // 清除用户信息
  [CLEAR_USERINFO](state) {
    state.userInfo = null;
  },
  // 设置房间状态
  [SET_ROOMSTATUS](state, data) {
    state.roomStatus = data;
  },
  // 添加游客
  [ADD_USER](state, data) {
    const { userList } = state;
    let status = true;
    userList.forEach((user) => {
      if (user.userId === data.userId) {
        status = false;
      }
    });
    if (status) {
      state.userList.push(data);
    }
    state.allUserList.push(data.userId);
  },
  // 移除游客
  [REMOVE_USER](state, data) {
    const { userList } = state;
    let pos = null;
    userList.forEach((user, index) => {
      if (user.userId === data.userId) {
        pos = index;
      }
    });
    if (pos === 0 || pos) {
      state.userList.splice(pos, 1);
    }
  },
  // 清空游客列表
  [CLEAR_USER](state) {
    state.userList = [];
    state.allUserList = [];
  },
  // 设置游客状态，禁言/非禁言
  [SET_USERSTATUS](state, data) {
    const userListTemp = JSON.parse(JSON.stringify(state.userList));
    let pos = null;
    userListTemp.forEach((user, index) => {
      if (user.userId === data.userId) {
        pos = index;
      }
    });
    userListTemp[pos] = data;
    state.userList = userListTemp;
  },
  // 设置直播房间信息
  [SET_LIVEINFO](state, data) {
    state.liveInfo = data;
  },
  // 设置单词直播结果
  [SET_LIVERESULT](state, data) {
    state.liveResult = data;
  },
  [SAVE_PERMISSION](state, data) {
    sessionStorage.setItem('permission', JSON.stringify(data));
    state.permissionData = data;
  },
};
