export default {
  permissionData: sessionStorage.getItem('permission')
    ? JSON.parse(sessionStorage.getItem('permission'))
    : [],
  userInfo: {
    // uuid: '',
    userAccountId: '',
    realName: '',
  },
  liveInfo: {
    appId: '',
    limitNumber: '',
    token: '',
  },
  roomStatus: false,
  // 直播房间活跃用户列表
  userList: [],
  // 所有进入过房间的用户
  allUserList: [],
  // 直播结果
  // liveResult: {
  //     livePv: '',
  //     liveUv: '',
  //     liveTime: '',
  // },
  labelArr: [
    {
      value: '1',
      label: '个人投保',
    },
    {
      value: '2',
      label: '企业投保',
    },
  ],
};
