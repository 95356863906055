/*
 * @LastEditTime: 2023-01-04 14:33:40
 * @Description: 主文件
 * @FilePath: /src/main.js
 */
import Vue from 'vue';
import Element from 'element-ui';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import fetch from '@/utils/fetch';
import configs from '@/config';
import router from './router';
import store from './store';
import { fileName } from './filters';

import './components';
import '@med/brick/lib/style/theme/default/index.css';

import './styles/normalize.scss';
import './styles/common.scss';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/iconfont/iconfont.css';

Vue.filter('fileName', fileName);

Vue.use(Element);
Vue.prototype.$fetch = fetch;
Vue.prototype.$bkConfig = configs;
// Vue.prototype.$utils = lodash;

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_DEPLOY_ENV !== 'test') {
  Sentry.init({
    Vue,
    dsn: 'https://230e146efd294b0480bb88cf71c65122@sentry.medbanks.cn/77',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
}).$mount('#app'); // 手动挂载
