/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-28 13:57:23
 * @Last Modified by: zhangzc
 * @Last Modified time: 2019-10-30 13:53:24
 * @Description: 递归菜单
 * @Route: Route
 */

<template>
  <div v-if="menuData && menuData.length">
    <template v-for="(item, i) in menuData">
      <el-submenu v-if="item.children"
                  :key="item.path || i"
                  :index="item.path||item.name">
        <template slot="title">
          <i v-if="item.icon"
             :class="item.icon"
             class="iconfont" />
          <span v-show="!collapse">{{ item.name }}</span>
        </template>
        <sidebar-menu :menu-data="item.children"
                      :default-active="defaultActive"
                      class="med-submenu" />
      </el-submenu>
      <el-menu-item v-else
                    :key="item.path"
                    :index="item.path"
                    :class="[defaultActive === item.path && 'menu-active-item']">
        <!--path跳转-->
        <i v-if="item.icon"
           :class="item.icon"
           class="iconfont" />
        <span v-show="!collapse">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  props: {
    menuData: {
      type: Array,
      default: () => ({}),
    },
    defaultActive: {
      type: String,
      default: '',
    },
    collapse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-submenu__title:hover,
.el-menu-item:hover,
.el-menu-item.is-active {
  background-color: $--color-primary !important;
}
.iconfont {
  padding-right: 20px;
  width: 20px;
  display: inline-block;
  font-size: 20px;
  text-align: center;
}
.med-submenu {
  > li {
    padding-left: 64px !important;
  }
}
/deep/.el-menu-item {
  padding-left: 24px !important;
}

/deep/.el-submenu {
  .el-submenu__title {
    padding-left: 24px !important;
  }
}
.med-menu-collapse {
  /deep/.el-menu-item {
    width: 64px !important;
  }
}
</style>
