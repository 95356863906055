
import Fetch from '@/utils/fetch';
// import Configs from '@/config';

export const getLiveList = params => (
  Fetch({
    url: '/api/im-web/admin/live/roomList',
    params,
  })
);

export const getRoomDetail = params => (
  Fetch({
    url: '/api/im-web/admin/live/queryAnchorDetailMess',
    params,
  })
);


// 设置直播间公告或者人数上限
export const updateAnchorMess = params => (
  Fetch({
    url: '/api/im-web/admin/live/updateAnchorMess',
    method: 'post',
    params,
  })
);


// 创建房间
export const createLiveRoom = params => (
  Fetch({
    url: '/api/im-web/admin/live/saveAnchorMess',
    method: 'post',
    params,
  })
);

// 开始直播
export const liveBegin = () => (
  Fetch({
    url: '/api/im-web/admin/live/begin',
    method: 'post',
  })
);

// 结束直播
export const liveEnd = () => (
  Fetch({
    url: '/api/im-web/admin/live/end',
    method: 'get',
  })
);


/**
 * @desc 获取客服渠道的视频列表
 * @param {id} params 客服渠道主键ID
 */
export const getRoomVideoList = params => (
  Fetch({
    url: '/api/im-web/admin/live/roomVideoList',
    params,
  })
);


/**
 * @desc 保存客服渠道的视频列表
 * @param {channelConfId} params 渠道ID
 */
export const saveRoomVideo = data => (
  Fetch({
    url: `/api/im-web/admin/live/saveRoomVideo?playType=${
      data.playType
    }&channelConfId=${data.channelConfId}`,
    method: 'post',
    params: data.params,
  })
);

/**
 * @desc 禁言
 * @param {expire} params 禁言时长
 */
export const forbid = params => (
  Fetch({
    url: '/api/im-web/admin/roomuser/forbid',
    method: 'post',
    params,
  })
);

/**
 * @desc 解除禁言
 * @param {expire} params 时长
 */
export const relieve = params => (
  Fetch({
    url: '/api/im-web/admin/roomuser/relieve',
    method: 'post',
    params,
  })
);
/**
 * @desc 踢出用户
 * @param {expire} params 时长
 */
export const removeUser = params => (
  Fetch({
    url: '/api/im-web/admin/roomuser/remove',
    method: 'post',
    params,
  })
);

export const getAllUser = roomId => (
  Fetch({
    url: `/api/im-web/admin/roomuser/list/${roomId}`,
    method: 'get',
  })
);
