/*
 * @Author: shuwen.chen
 * @Email: shuwen.chen@medbanks.cn
 * @Date: 2020-12-25 11:38:29
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2020-12-30 14:06:00
 * @Description: 知识库接口
 * @Route: Route
 */
import Fetch from '@/utils/fetch';

export default {
  // 删除
  deleteProblem(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/deleteProblem',
      params,
      method: 'get',
    });
  },
  // 下载导入模版
  downloadTempUrl(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/downloadTempUrl',
      params,
      method: 'post',
    });
  },
  // 导出文件
  exportFile(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/exportFile',
      params,
      method: 'post',
    });
  },
  importFile(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/importFile',
      params,
      method: 'post',
    });
  },
  // 创建问题内部编号字段校验接口
  verifyOnlyMess(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/verifyOnlyMess',
      params,
      method: 'get',
    });
  },
  // 上传图片 (裁剪用)
  imagesUploader(store, params) {
    return Fetch({
      url: '/api/im-web/file/upload',
      params,
      method: 'post',
    });
  },
  // 置顶
  topProblem(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/topProblem',
      params,
      method: 'get',
    });
  },
  // 知识库-知识管理-批量删除
  deleteProblemBatch(store, params) {
    return Fetch({
      url: '/api/im-web/knowledge/deleteProblemBatch',
      params,
      method: 'post',
    });
  },
};
