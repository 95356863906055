import Fetch from '@/utils/fetch';
// import Configs from '@/config';

/**
 * @desc 获取客服渠道列表
 * @param {channelName} params 如果是搜索
 */
export const getList = params => Fetch({
  url: '/api/im-web/admin/channel/list',
  params,
});

/**
 * @desc 根据ID获取客服渠道详情
 * @param {id} params 渠道ID
 */
export const getDetailByChannelId = params => Fetch({
  url: '/api/im-web/admin/channel/getDetailByChannelId',
  params,
});


/**
 * @desc 字段的重复性校验
 * @param {type} params 字段名
 * @param {value} params 字段值
 * @param {id} params 当前设置的渠道ID
 */
export const verifyField = params => Fetch({
  url: '/api/im-web/admin/channel/verifyOnlyMess',
  params,
});


/**
 * @desc 获取主数据渠道列表
 * @param {channelName} params 搜索的渠道名称
 */
export const getChannelList = params => Fetch({
  url: '/api/im-web/admin/channel/queryChannelMess',
  params,
});

/**
 * @desc 保存或新增
 * @param {channelConfReqDTO} params 保存渠道数据
 */
export const saveChannelMess = params => (
  Fetch({
    url: '/api/im-web/admin/channel/saveChannelMess',
    method: 'post',
    params,
  })
);
