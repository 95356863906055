/*
 * @Last Modified time: 2022-03-22 13:37:28
 * @Description: 路由集成
 * @Route: Route
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/utils/util';
import Config from '@/config';
import DefaultLayout from '@/components/layout/default';
import View from '@/components/layout/view';
import Statistics from '../pages/data-statistics/index';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/pages/not-found');
// const Workbench = () => import(/* webpackChunkName: "live-host" */ '@/pages/workbench');
// const RoomList = () => import(/* webpackChunkName: "room-list" */ '@/pages/live/room-list');
// const liveResult = () => import(/* webpackChunkName: "live-result" */ '@/pages/result');
const Forbid = () => import(/* webpackChunkName: "forbid" */ '@/pages/forbid');

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      // {
      //   path: 'room-list',
      //   component: () => import(/* webpackChunkName: "room-list" */ '@/pages/live/room-list'),
      //   meta: {
      //     title: '直播大厅',
      //     code: 'KFXT_ZSK',
      //     icon: 'icon-zhibodating',
      //     // display: 'none',
      //   },
      // },
      // {
      //   path: 'room/:id',
      //   component: () => import(/* webpackChunkName: "room" */ '@/pages/live/room'),
      //   meta: {
      //     title: '查看直播间',
      //     code: 'KFXT_ZSK',
      //     noStyle: true,
      //     display: 'none',
      //   },
      // },
      // {
      //   path: 'workbench',
      //   component: Workbench,
      //   meta: {
      //     title: '直播工作台',
      //     noStyle: true,
      //     code: 'KFXT_ZSK',
      //     icon: 'icon-zhibogongzuotai',
      //     // display: 'none',
      //   },
      // },
      // {
      //   path: 'setting',
      //   component: () => import(/* webpackChunkName: "setting-index" */ '@/pages/setting/index'),
      //   meta: {
      //     title: '直播渠道设置',
      //     code: 'KFXT_ZSK',
      //     icon: 'icon-zhiboqudaoguanli',
      //     // display: 'none',
      //   },
      // },
      // {
      //   path: 'result',
      //   component: liveResult,
      //   meta: {
      //     title: '直播结果',
      //     // display: 'none',
      //     auth: false,
      //   },
      // },
      {
        path: 'data-statistics',
        component: Statistics,
        children: [
          {
            path: 'index',
            name: 'statistics_index',
            alias: '/',
            component: () => import('../pages/data-statistics/data-statistics'),
            meta: {
              title: '数据统计',
            },
          },
          {
            path: 'export',
            name: 'statistics_export',
            component: () => import('../pages/data-statistics/export'),
            meta: {
              title: '数据统计导出',
            },
          },
        ],
        meta: {
          title: '数据统计',
          display: 'none',
          code: 'KFXT_SJTJ',
        },
      },
      {
        alias: '/',
        path: 'question',
        name: 'question',
        component: View,
        meta: {
          title: '知识库',
          code: 'KFXT_ZSK',
          icon: 'icon-zhishiku',
        },
        children: [
          {
            path: 'list',
            alias: '/',
            name: 'questionList',
            component: () => import('../pages/question/questionList'),
            meta: {
              title: '知识管理',
              code: 'KFXT_ZSK_ZSKLB',
              headerName: '知识管理',
              setHeader: true,
            },
          },
          {
            path: 'recordList',
            name: 'recordList',
            component: () => import('../pages/question/recordList'),
            meta: {
              title: '导入记录',
              code: 'KFXT_ZSK_ZSKBJ',
              display: 'none',
              setHeader: true,
            },
          },
          {
            path: 'exportList',
            name: 'exportList',
            component: () => import('../pages/question/recordList'),
            meta: {
              title: '导出记录',
              code: 'KFXT_ZSK_ZSKBJ',
              display: 'none',
              setHeader: true,
            },
          },
          {
            path: 'detail',
            name: 'questionDetail',
            component: () => import('../pages/question/questionDetail'),
            meta: {
              title: '问题设置',
              code: 'KFXT_ZSK_ZSKBJ',
              display: 'none',
              setHeader: true,
            },
          },
          {
            path: 'show',
            name: 'questionShow',
            component: () => import('../pages/question/questionShow'),
            meta: {
              title: '问题详情',
              code: 'KFXT_ZSK_ZSKBJ',
              display: 'none',
              headerName: '问题详情',
              setHeader: true,
            },
          },
          {
            path: 'classManage',
            name: 'classManage',
            component: () => import('../pages/question/classManage'),
            meta: {
              title: '分类管理',
              code: 'KFXT_ZSK_FLGL',
            },
          },
          {
            path: 'sourceManage',
            name: 'sourceManage',
            component: () => import('../pages/question/sourceManage'),
            meta: {
              title: '来源管理',
              code: 'KFXT_ZSK_LYGL',
            },
          },
        ],
      },
      {
        path: 'questionTemplate',
        name: 'questionTemplate',
        component: () => import('../pages/questionTemplate/list'),
        meta: {
          title: '知识库模板列表',
          code: 'KFXT_ZSKMB_ZSKMBLB',
          icon: 'icon-zhishikumoban',
        },
      },
      {
        path: 'questionTemplateDetail',
        name: 'questionTemplateDetail',
        component: () => import('../pages/questionTemplate/detail'),
        meta: {
          title: '知识库模板编辑',
          code: 'KFXT_ZSKMB_ZSKMBBJ',
          display: 'none',
        },
      },
      {
        path: 'questionTemplateShow',
        name: 'questionTemplateShow',
        component: () => import('../pages/questionTemplate/show'),
        meta: {
          title: '知识库模板详情',
          code: 'KFXT_ZSKMB_ZSKMBLB',
          display: 'none',
        },
      },

      {
        path: 'im',
        component: () => import(/* webpackChunkName: "im-index" */ '@/pages/im-channel/index'),
        meta: {
          title: '客服页面配置',
          code: 'KFXT_IKFYMPZ',
          icon: 'icon-IMkefuyemianpeizhi3x',
        },
      },
      {
        path: 'im-edit',
        component: () => import(/* webpackChunkName: "im-index" */ '@/pages/im-channel/edit'),
        meta: {
          title: '客服页面配置',
          display: 'none',
          code: 'KFXT_IKFYMPZ',
        },
      },
      {
        path: 'im-detail/:id',
        component: () => import(/* webpackChunkName: "im-index" */ '@/pages/im-channel/detail'),
        meta: {
          title: '客服页面配置详情',
          display: 'none',
          code: 'KFXT_IKFYMPZ',
        },
      },
    ],
  },
  {
    path: '/forbid',
    component: Forbid,
    meta: {
      title: '无权限访问',
    },
  },
  {
    path: '*',
    component: DefaultLayout,
    children: [
      {
        path: '*',
        component: NotFound,
        meta: {
          title: '404',
          noTag: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (!token) {
    const { href } = window.location;
    window.location.href = `${Config.loginUrl}?sourceUrl=${href}`;
  } else {
    next();
  }
});

export default router;
