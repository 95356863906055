/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-30 13:32:22
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2020-12-31 13:43:24
 * @Description: 默认布局，拥有头部+左侧菜单
 * @Route: Route
 */

<template>
  <div class="med-container">
    <div :class="{ collapse: isMenuHide }" class="left-wrapper">
      <div class="logo-wrapper head-left">客服管理</div>
      <el-menu
        :default-active="routerIndex"
        :class="{ 'med-menu-collapse': isMenuHide }"
        :collapse="isMenuHide"
        :unique-opened="isSingleShow"
        @select="handleSelect"
        class="med-menu"
        background-color="#001529"
        text-color="rgba(255, 255, 255, 0.64)"
        active-text-color="#fff"
      >
        <SidebarMenu :menu-data="menuData" :collapse="isMenuHide" :default-active="routerIndex" />
      </el-menu>
    </div>

    <div class="med-content">
      <common-header
        :class="{ 'header-collapse': isMenuHide }"
        :is-menu-hide="isMenuHide"
        @onCollapse="handleMenu"
      />
      <div :class="!headerMes.setHeader ? 'right-wrapper' : ''">
        <!-- 右侧主体区域 -->
        <div
          :class="[!headerMes.setHeader ? 'right-content' : '', routerMeta.noStyle && 'no-style']"
        >
          <!--主体部分-->
          <div v-if="headerMes.setHeader" class="header_name_wrap">
            <el-breadcrumb v-if="breadcrumbData && breadcrumbData.length > 1" separator="/">
              <template v-for="(item, index) in breadcrumbData">
                <el-breadcrumb-item v-if="breadcrumbData.length === index + 1" :key="index">
                  {{ item.meta.title }}
                </el-breadcrumb-item>
                <el-breadcrumb-item
                  v-else
                  :key="index"
                  :to="{ path: item.path, query: item.query }"
                >
                  {{ item.meta.title }}
                </el-breadcrumb-item>
              </template>
              <!-- <el-breadcrumb-item v-for="(item, index) in breadcrumbData"
                                                :key="index"
                                                :to="{ path: item.path }">
                                {{ item.meta.title }}
                            </el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <div class="right_wrap">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserPermission } from '@/api';
import CommonHeader from './header';
import SidebarMenu from './sidebar-menu';
import { SAVE_PERMISSION } from '../../store/mutation-type';

export default {
  components: {
    CommonHeader,
    SidebarMenu,
  },
  data() {
    return {
      isSingleShow: true,
      menuData: [], // 菜单数据
      menuSourceData: [], // 菜单数据
      routerIndex: '', // 当前菜单
      isMenuHide: false, // 是否折叠菜单
      menuCode: '', // 缓存菜单id
      menuQuery: '', // 搜索菜单数据
      routePermission: [], // 路由权限数组
      routerMeta: {},
      headerMes: this.$route.meta,
      breadcrumbData: [],
    };
  },
  watch: {
    $route(val) {
      this.routerIndex = val.path;
      this.routerMeta = val.meta;
      this.headerMes = this.$route.meta;
      this.breadcrumbData = this.cutTop(val.matched);
    },
  },
  created() {
    this.getUserAuth();
    this.handleMenuSelectInit();
    this.breadcrumbData = this.cutTop(this.$route.matched);
  },
  methods: {
    cutTop(arr) {
      const newArr = arr.slice(0);
      newArr.shift();
      return newArr;
    },
    /*
     * 菜单折叠，暂时不需要
     */
    handleMenu() {
      this.isMenuHide = !this.isMenuHide;
    },

    // 获取用户权限
    getUserAuth() {
      getUserPermission({}).then(res => {
        const permissionData = [];
        const { data } = res;
        if (res.code === '0' && res.data) {
          data.forEach(item => {
            // permissionData.push(item);
            // console.log('item=> ', item);
            const sourceCode = item?.sourceCode;
            permissionData.push(sourceCode);
          });
        }

        // console.log('permissionData: ', permissionData);

        this.$store.commit(SAVE_PERMISSION, permissionData);
        this.routePermission = permissionData;
        if (this.checkUserPermission(this.$route) || this.$route.meta.auth === false) {
          this.menuData = this.createTreeMenu(1);
          this.menuSourceData = JSON.parse(JSON.stringify(this.menuData));
        } else {
          this.$router.push('/forbid');
        }
      });
    },

    /*
     * 根据扁平数据生成树形菜单
     * @param {Array} data 所有路由的数据
     * @param {Array} menuData 树形菜单数据
     * @param {Number} type 生成数据类型（1: 不包含二级三级数据，2: 包含二级三级数据源）
     */
    createTreeMenu(type, data = this.$router.options.routes[0], menuData = []) {
      if (!Array.isArray(data.children) || !data.children.length) return null;
      data.children.forEach(obj => {
        const item = obj;
        if (type === 1 && item.meta?.display === 'none') {
          return '';
        }
        let menuItem = {};
        if (Array.isArray(item.children) && item.children.length && this.checkUserPermission(obj)) {
          item.parentPath = data.parentPath ? `${data.parentPath}/${item.path}` : item.path;
          menuItem = {
            path: item.path,
            name: item.meta ? item.meta.title : '',
            icon: item.meta.icon,
            children: [],
          };
          menuData.push(menuItem);
          return this.createTreeMenu(type, item, menuItem.children);
        }
        menuItem = {
          path: data.parentPath ? `/${data.parentPath}/${item.path}` : `/${item.path}`,
          name: item.meta.title,
          icon: item.meta.icon,
        };
        if (this.checkUserPermission(obj)) {
          menuData.push(menuItem);
        }
        return '';
      });
      return menuData;
    },

    /**
     * 判断用户是否拥有权限
     * @param {Object} obj 菜单
     */
    checkUserPermission(obj) {
      const { routePermission } = this;
      let status = false;
      if (Array.isArray(routePermission)) {
        routePermission.forEach(code => {
          if (code === obj.meta.code) {
            status = true;
          }
        });
      }
      return status;
    },

    /*
     * 初始化页面时根据路径选中菜单项
     */
    handleMenuSelectInit() {
      const { path, meta } = this.$route;
      this.routerIndex = path;
      this.routerMeta = meta;
    },

    /*
     * 路由跳转
     */
    handleSelect(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.med-container {
  height: 100%;
  background-color: $--background-color-light;

  /deep/ .el-container {
    overflow: hidden;
  }
  /deep/ .el-breadcrumb__inner.is-link {
    color: $--color-primary;
    font-weight: 400;

    &:hover {
      color: $--color-primary;
      font-weight: 700;
    }
  }

  .logo-wrapper {
    width: 230px;
    height: 60px;
    // background-color: $--color-primary;
    background-color: #002140;
    color: $--color-white;
    text-align: center;
    font-size: 16px;
    line-height: 60px;

    img {
      width: 37px;
      height: 32px;
    }
  }
}
.med-content {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 60px;
  .header-collapse {
    width: calc(100% - 64px);
    transition: width ease 0.3s;
  }
}
.left-wrapper {
  position: relative;
  float: left;
  width: 230px;
  height: 100%;
  transition: width ease 0.3s;
  background-color: $--background-color-menu-light;

  .el-menu {
    border: 0;
  }
  /deep/.el-menu--collapse {
    .el-submenu__icon-arrow {
      display: none;
    }
    .el-menu-item {
      width: 64px;
    }
    .el-submenu {
      width: 64px;
    }
  }
  /deep/ .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
  /deep/ .el-submenu__title {
    height: 40px;
    line-height: 40px;

    .el-submenu__icon-arrow {
      // color: $--color-white;
      font-size: 14px;
      font-weight: 700;
    }
  }
  /deep/ .med-submenu {
    .el-menu-item,
    .el-submenu__title {
      background-color: $--background-color-menu-base !important;

      &:hover,
      &.is-active {
        // background-color: $--color-primary !important;
        background-color: #1890ff !important;
      }
    }
  }
  /deep/.is-active {
    > div {
      color: #fff !important;
      > i {
        color: #fff !important;
      }
    }
  }
}
.collapse {
  width: 64px;
}
/deep/.el-breadcrumb__separator {
  margin: 0 2px 0 1px;
}
.med-menu {
  position: absolute;
  top: 60px;
  bottom: 66px;
  // width: 100%;
  overflow: auto;
  // transition: all ease 0.3s;
  > div {
    // transition: all ease 0.3s;
    width: 230px;
  }
}
.med-menu-collapse {
  // transition: all ease 0.3s;
  > div {
    // transition: all ease 0.3s;
    width: 60px;
  }
}
.no-back-btn {
  bottom: 0;
}
.back-btn {
  position: absolute;
  width: 100%;
  height: 66px;
  bottom: 0;
}
.back-tender {
  display: block;
  width: 224px;
  height: 40px;
  margin: 13px auto;
  font-size: $--font-size-base;
  font-weight: 400;
  color: $--color-white;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  background-color: $--background-color-menu-base;
  border-radius: 20px;
  border: 1px solid $--color-primary;
  .back-icon {
    display: inline-block;
    width: 13px;
    height: 8px;
    background-image: url('$--image-path-base/arrow-left.png');
    background-repeat: no-repeat;
    background-size: 13px 8px;
  }
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 1px;
  height: 1px;
}
.menu-search-wrapper {
  position: absolute;
  top: 0;
  // width: calc(100% - 230px);
  padding: 9px 16px;
  height: 50px;
  box-sizing: border-box;
  background-color: $--background-color-menu-base;

  /deep/ .el-input {
    border-radius: 4px;
    overflow: hidden;

    .el-input__inner {
      padding-right: 40px;
      height: 32px;
      line-height: 32px;
      background-color: $--background-color-menu-light;
      border: 0;
      color: $--color-white;
    }
    .el-input__suffix {
      right: 0;
    }
    .el-input__icon {
      width: 40px;
      line-height: 32px;
      font-size: 16px;
      color: $--color-white;
      background-color: $--color-primary;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.right-wrapper {
  position: relative;
  // margin-left: 230px;
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
}
.med-breadcrumb {
  padding-bottom: 10px;
  margin-top: -6px;
  font-size: $--font-size-extra-small;
}
.breadcrumb-desc {
  float: left;
  color: $--color-text-secondary;
}
.right-content {
  background-color: $--color-white;
  position: relative;
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.no-style {
  background: transparent;
  padding: 0;
}
.right-content.has-breadcrumb {
  height: calc(100% - 12px);
  overflow-y: auto;
}
.btn-back {
  cursor: pointer;
}
.header_name_wrap {
  background: #fff;
  padding: 15px 32px;
  .superior_name {
    color: #00000073;
    font-size: 14px;
  }
}
.question_details {
  height: calc(100vh - 180px);
  overflow: scroll;
}
</style>
