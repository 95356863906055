import state from './state';
import getters from './getter';
import mutations from './mutation';
import actions from './action';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
