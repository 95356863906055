/*
 * @Last Modified time: 2020-12-31 18:07:12
 * @Description: 工具函数
 * @Route: util
 */

/**
 * @function 判断是否空对象
 * @param val:对象
 * @returns true | false
 */
export const isEmptyObj = val => {
  if (Object.prototype.toString.call(val) === '[object Undefined]') return true;
  if (Object.prototype.toString.call(val) === '[object Null]') return true;
  if (Object.prototype.toString.call(val) === '[object String]' && val === '') return true;
  return false;
};

/**
 * 日期格式化
 * (new Date(), 'yyyy-mm-dd hh:ii:ss.S')==> 2006-07-02 08:09:04.423
 * (new Date(), 'yyyy-mm-dd E HH:ii:ss') ==> 2009-03-10 二 20:09:04
 * (new Date(), 'yyyy-mm-dd EE hh:ii:ss') ==> 2009-03-10 周二 08:09:04
 * (new Date(), 'yyyy-mm-dd EEE hh:ii:ss') ==> 2009-03-10 星期二 08:09:04
 * (new Date(), 'yyyy-m-d h:m:s.S') ==> 2006-7-2 8:9:4.18
 * (new Date(), 'yyyy/mm/dd hh:ii:ss.S') ==> 2017/04/17 10:00:48.282
 */
export const formatDate = (value, fmt) => {
  if (!value) {
    return null;
  }
  // ie
  let date = value;
  if (String(date).match('-')) {
    date = date.replace('-', '/');
  }
  /* eslint-disable no-param-reassign */
  date = new Date(date);
  /* eslint no-console: 'off' */
  if (date.toString() === 'Invalid Date') {
    console.log('日期不合法');
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份,
    'm+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours() % 24 === 0 ? 0 : date.getHours() % 24, // 小时
    'H+': date.getHours(), // 小时
    'i+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  const week = {
    0: '/u65e5',
    1: '/u4e00',
    2: '/u4e8c',
    3: '/u4e09',
    4: '/u56db',
    5: '/u4e94',
    6: '/u516d',
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (match, p1) => {
      if (p1.length > 1) {
        return (p1.length > 2 ? '/u661f/u671f' : '/u5468') + week[`${date.getDay()}`];
      }
      return week[`${date.getDay()}`];
    });
  }
  const keys = Object.keys(o);
  for (let k = 0, len = keys.length; k < len; k += 1) {
    const prop = keys[k];
    if (new RegExp(`(${prop})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[prop] : `00${o[prop]}`.substr(`${o[prop]}`.length),
      );
    }
  }
  /* eslint-disable consistent-return */
  return fmt;
};

/*
 * 获取地址栏参数
 */
export const getUrlParame = paramName => {
  const urlParams = {};
  const url = window.location.href;
  const idx = url.indexOf('?');
  if (idx === -1) {
    return false;
  }
  let params = url.substring(url.indexOf('?') + 1);
  params = params.split('#');
  params = params[0].split('&');
  params.forEach(item => {
    const param = item.split('=');
    urlParams[param[0]] = decodeURIComponent(param[1]);
  });
  if (paramName) {
    return urlParams[paramName];
  }
  return urlParams;
};

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export const findTargetByDeepTraverse = (source, target, result = []) => {
  const localSource = JSON.parse(JSON.stringify(source));
  localSource.forEach(item => {
    if (item.name.indexOf(target) !== -1) {
      result.push(item);
    } else if (item.children && item.children.length) {
      findTargetByDeepTraverse(item.children, target, result);
    }
  });
  return result;
};

/**
 * 截取地址栏最后一栏地址
 */
export const checkPageType = url => {
  const urlArr = url.split('/');
  const len = urlArr.length;
  return urlArr[len - 1];
};

/**
 * 动态生成链接加载
 * @param {String} url js资源链接
 * @param {String} type 链接类型，1：普通资源，2：jsonp请求
 */
export const loadScriptAsync = (url, type = 1) =>
  new Promise((resolve, reject) => {
    if (type === 2) {
      window.callback = res => {
        resolve(res);
      };
    }
    const script = document.createElement('script');
    script.src = url;
    document.head.appendChild(script);
    if (type === 1) {
      script.onload = () => {
        resolve();
      };
    }
    script.onerror = () => {
      reject();
    };
  });

/**
 * 将字符串中的\n换行符替换为html中的<br />换行标签
 * @param {String} data 字符串
 */
export const translateToBr = data => data && data.replace(/\n/g, '<br />');

/**
 * 将html中的<br />换行符替换为字符串中的\n换行标签
 * @param {String} data 字符串
 */
export const translateToN = data => data && data.replace(/<br \/>/g, '\n');

/**
 * 获取cookie
 */
export const getCookie = name => {
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  const result = document.cookie.match(reg);
  if (result) {
    return unescape(result[2]);
  }
  return null;
};

/**
 * 基于毫秒数生成 hh:ii:ss 格式的耗时
 * @param {String} millisecond 毫秒数
 */
export const formatTime = millisecond => {
  const hour = parseInt(millisecond / 3600000, 10);
  const min = parseInt((millisecond - hour * 3600000) / 60000, 10);
  const second = parseInt((millisecond - hour * 3600000 - min * 60000) / 1000, 10);

  return `${hour}:${min}:${second}`;
};

/**
 * 去除数据内重复
 * @param {Array} arr 数组
 */
export const arrayRepetition = arr => {
  const newArr = [];
  const obj = {};
  arr.forEach(item => {
    if (!obj[item]) {
      obj[item] = 1;
      newArr.push(item);
    }
  });
  return newArr;
};

export const isArray = val => Object.prototype.toString.call(val) === '[object Array]';

export const copy = content =>
  new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', content);
    document.body.appendChild(input);
    input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
      input.select();
      document.execCommand('copy');
      resolve();
    } else {
      reject(new Error('浏览器暂时不支持复制'));
    }
    document.body.removeChild(input);
  });
/**
 *
 * @param {*} ele
 * @param {*} rowCount
 * @param {*} cssStyles
 * @param {*} removeChild
 */
export const isElementCollision = (ele, rowCount = 1, cssStyles, removeChild) => {
  if (!ele) {
    return false;
  }
  const clonedNode = ele.cloneNode(true);
  // 给clone的dom增加样式
  clonedNode.style.overflow = 'visible';
  clonedNode.style.display = 'inline-block';
  clonedNode.style.width = 'auto';
  clonedNode.style.whiteSpace = 'nowrap';
  clonedNode.style.visibility = 'hidden';
  // 将传入的css字体样式赋值
  if (cssStyles) {
    Object.keys(cssStyles).forEach(item => {
      clonedNode.style[item] = cssStyles[item];
    });
  }

  // 给clone的dom增加id属性
  const containerID = 'collision_node_id';
  clonedNode.setAttribute('id', containerID);

  const tmpNode = document.getElementById(containerID);
  let newNode = clonedNode;
  if (tmpNode) {
    document.body.replaceChild(clonedNode, tmpNode);
  } else {
    newNode = document.body.appendChild(clonedNode);
  }
  // 新增的dom宽度与原dom的宽度*限制行数做对比
  const differ = newNode.offsetWidth - ele.offsetWidth * rowCount;
  if (removeChild) {
    document.body.removeChild(newNode);
  }
  return differ > 0;
};
/**
 * 关键字高亮
 * @param { titles } 高亮文字
 */
export const keywordChangeColor = (item, keyword) => {
  if (item && keyword) {
    return item.replace(new RegExp(keyword, 'g'), `<font color=red>${keyword}</font>`);
  }
  if (item) {
    return item;
  }
  return '';
};

/**
 * 解析html中图片标签转换为 【图片】点击图片放大
 * @param { string } srcA 每条数据所有图片
 * @param { string } src[1] 当前图片
 * @param { } newHtml 转化后的html
 */
export const analysisImg = (str, num) => {
  const imgReg = /<img.*?(?:>|\/>)/gi;
  // eslint-disable-next-line no-useless-escape
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
  const arr = str.match(imgReg);
  let newHtml = str;
  if (num) {
    if (!arr) {
      return 0;
    }
    return arr.length;
  }
  if (arr && arr.length > 0) {
    const srcA = [];
    for (let i = 0; i < arr.length; i += 1) {
      const src = arr[i].match(srcReg);
      srcA.push(src[1]);
    }
    for (let i = 0; i < arr.length; i += 1) {
      const src = arr[i].match(srcReg);
      // 获取图片地址
      newHtml = newHtml.replace(
        /<img.*?(?:>|\/>)/,
        `<span style='color:#1890FF;cursor: 
                              pointer;margin-left: 10px;' 
                              onclick='previewHandle("${src[1]}", "${srcA}")'>
                              【图片】
                </span>`,
      );
    }
  }
  return newHtml;
};

export const getToken = () => {
  let token = getUrlParame('token');
  if (token) {
    window.sessionStorage.setItem('token', token);
  } else {
    token = window.sessionStorage.getItem('token');
  }
  return token;
};
