import Fetch from '@/utils/fetch';

// 来源
export const getOriginList = params => Fetch({
  url: '/api/im-web/knowledge/originList',
  method: 'get',
  params,
});

export const searchOrigin = params => Fetch({
  url: '/api/im-web/knowledge/originQueryList',
  method: 'get',
  params,
});

export const addOriginMess = params => Fetch({
  url: '/api/im-web/knowledge/submitOriginMess',
  method: 'post',
  params,
});
export const delkOrigin = id => Fetch({
  url: '/api/im-web/knowledge/deleteOrigin',
  method: 'get',
  params: { id },
});

// 分类

export const getClassList = params => Fetch({
  url: '/api/im-web/knowledge/classificaPageList',
  method: 'get',
  params,
});

export const addClass = params => Fetch({
  url: '/api/im-web/knowledge/submitClassificaMess',
  method: 'post',
  params,
});

// 标签

export const getProblemLableList = params => Fetch({
  url: '/api/im-web/knowledge/problemLableList',
  method: 'get',
  params,
});

// 问题
export const saveProblem = params => Fetch({
  url: '/api/im-web/knowledge/submitProblemMess',
  method: 'post',
  params,
});

export const getQuestionDetail = params => Fetch({
  url: '/api/im-web/knowledge/problemDetail',
  method: 'get',
  params,
});

// 模板
export const saveTemplateMess = params => Fetch({
  url: '/api/im-web/knowledge/submitTemplateMess',
  method: 'post',
  params,
});

// 知识库列表
export const callSearchProblemList = params => Fetch({
  url: '/api/im-web/knowledge/callSearchProblemList',
  method: 'get',
  params,
});
export const getTemplateDetail = params => Fetch({
  url: '/api/im-web/knowledge/templateDetail',
  method: 'get',
  params,
});

export const uploader = params => Fetch({
  url: '/api/prescription-project/v1/storage/create',
  method: 'post',
  params,
});

export const originList = params => Fetch({
  url: '/api/im-web/knowledge/originList',
  method: 'get',
  params,
});

// 分类
export const classificaPageList = params => Fetch({
  url: '/api/im-web/knowledge/classificaPageList',
  method: 'get',
  params,
});
