/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-11-08 11:05:16
 * @Last Modified by: lynn
 * @Last Modified time: 2022-10-08 10:34:25
 * @Description: 统一接口定义
 * @Route: Route
 */

import Fetch from '@/utils/fetch';
// import Configs from '@/config';

import * as imChannel from './im-channel';
import * as live from './live';
import * as setting from './setting';
import * as question from './questions';

export { imChannel, live, setting, question };

// 用户退出登陆
export const userLoginout = (params) =>
  Fetch({
    url: '/api/inneruserproject/v001/login/logout',
    params,
    method: 'post',
  });

// 获取用户权限 params
export const getUserPermission = (params) =>
  Fetch({
    // url: '/master-data/user/permission',
    // params: { ...params, subsysId: 20 },
    url: '/api/inneruserproject/v001/auth/page/query_auths',
    params: { ...params, subsys: 20 },
  });

// 获取用户信息
export const userInfoApi = (params) =>
  Fetch({
    // url: '/master-data/user/information',
    url: '/api/inneruserproject/v001/user/get_account_info',
    params,
  });

// 获取渠道列表
export const getChannelList = (params) =>
  Fetch({
    url: '/api/im-web/admin/channel/commonChannelList',
    params,
  });
