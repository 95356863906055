/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-11-19 17:03:38
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2020-10-09 19:45:56
 * @Description: mutation常量管理
 * @Route: Route
 */

// 用户信息
export const SET_USERINFO = 'SET_USERINFO';
// 清除用户信息
export const CLEAR_USERINFO = 'CLEAR_USERINFO';
// 设置房间状态
export const SET_ROOMSTATUS = 'SET_ROOMSTATUS';
// 增加观众
export const ADD_USER = 'ADD_USER';
// 删除观众
export const REMOVE_USER = 'REMOVE_USER';
// 清除所有观众
export const CLEAR_USER = 'CLEAR_USER';
// 添加直播信息
export const SET_LIVEINFO = 'SET_LIVEINFO';
// 设置用户状态
export const SET_USERSTATUS = 'SET_USERSTATUS';
// 设置单次直播结果
export const SET_LIVERESULT = 'SET_LIVERESULT';

// 保存用户权限
export const SAVE_PERMISSION = 'SAVE_PERMISSION';
