/*
 * @Last Modified time: 2020-12-29 18:27:12
 * @Description: 公共请求封装
 * @Route: fetch
 */
import Axios from 'axios';
import Vue from 'vue';
import Configs from '@/config';
import { getToken } from './util';

const Vm = new Vue();

const logout = () => {
  window.sessionStorage.removeItem('token');
  window.localStorage.removeItem('token');
  window.location.href = `${Configs.loginUrl}?sourceUrl=${encodeURIComponent(
    window.location.href,
  )}`;
};
const fetch = async config => {
  const { method, params, independent, url, timeout, ...other } = config;
  const token = getToken();

  const headers = {
    token,
  };
  // 创建axios实例
  const axiosIns = Axios.create();
  let options = {
    headers,
    baseURL: Configs.baseUrl,
    url,
    ...other,
    timeout: timeout || 1 * 60 * 1000,
  };

  if (method === 'post') {
    options = {
      ...options,
      method: 'post',
      data: params,
    };
  } else {
    options = {
      ...options,
      method: 'get',
      params,
    };
  }

  const response = await axiosIns(options)
    .then(res => {
      const { code, message } = res.data || {};
      const codeTemp = parseInt(code, 10);
      if (
        code === '10021' ||
        code === '10006' ||
        code === '10040' ||
        code === '10042' ||
        codeTemp === 1001
      ) {
        Vm.$message.error(message);
        // 登出
        logout();
      }
      if (independent)
        // independent 存在做单独异常处理，其余走统一业务处理
        return res.data;
      if (codeTemp !== 0) {
        // code不为0视为错误
        Vm.$message.error(message);
        return Promise.reject(res.data.message);
      }
      return res.data;
    })
    .catch(error => {
      const { response: errRes } = error;
      if (errRes && errRes.status) {
        Vm.$message.error('系统异常');
        if (Number(errRes.status) === 401) {
          logout();
        }
      } else if (!errRes) {
        Vm.$message.error('系统异常');
      }
      return false;
    });

  return response;
};

export default fetch;
