/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2019-11-18 16:37:46
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2019-11-18 18:31:22
 * @Description: Description
 * @Route: Route
 */
<template>
  <div class="statistics">
    <el-button-group>
      <el-button :type="$route.name === 'statistics_index' ? 'primary' : ''"
                 @click="change_router('/data-statistics/index')">
        数据概览
      </el-button>
      <el-button :type="$route.name === 'statistics_export' ? 'primary' : ''"
                 @click="change_router('/data-statistics/export')">
        数据导出
      </el-button>
    </el-button-group>

    <div class="statistics_container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    change_router(path) {
      this.$router.push(path);
    },
  },
};
</script>
