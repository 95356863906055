/*
 * @Last Modified time: 2020-07-22 17:18:45
 * @Description: Description
 * @Route: Config
 */
import dev from './config.dev';
import prod from './config.prod';
import test from './config.test';
import base from './base';

let config = {};
const { host } = window.location;
switch (host) {
  case 'service.medbanks.cn':
    config = prod;
    break;
  case 'service.medbanks-test.com':
    config = test;
    break;
  default:
    config = dev;
}

export default Object.assign(config, base);
