/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-28 13:39:14
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2020-07-29 21:00:52
 * @Description: 统一公共头部
 * @Route: Route
 */

<template>
  <div class="hx-header">
    <div class="head-right clearfix">
      <i :class="{'el-icon-s-fold': !isMenuHide, 'el-icon-s-unfold': isMenuHide}"
         @click="onClick"
         class="icon " />
      <div v-if="showAccountInfo"
           class="user-right-box">
        <span class="user-name-wrapper">
          <span class="tip-txt">您好，</span>
          <span class="user-name">
            {{ userInfo.realName }}
          </span>
        </span>
        <span class="vertical-line">|</span>
        <span @click="handleLogout"
              class="user-exit">
          退出
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import config from '@/config';

export default {
  props: {
    showAccountInfo: {
      type: Boolean,
      default: true,
    },
    isMenuHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: '---',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
      fetchLoginOut: 'fetchLoginOut',
    }),
    /**
         * 获取用户信息
         */
    getUserInfo() {
      this.fetchUserInfo();
    },

    /**
         * 退出登陆
         */
    handleLogout() {
      this.fetchLoginOut().then(() => {
        const { href } = window.location;
        window.location.href = `${config.loginUrl}?sourceUrl=${
          encodeURIComponent(href)
        }`;
      });
    },
    onClick() {
      this.$emit('onCollapse');
    },
  },
};
</script>

<style lang="scss" scoped>
.hx-header {
  position: absolute;
  top: 0;
  z-index: 9;
  width: calc(100% - 230px);
  height: 60px;
  line-height: 60px;
  color: $--color-text-regular;
  font-size: 14px;
  background-color: $--color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: width ease 0.3s;

  .logo-wrapper {
    float: left;
    width: 230px;
    height: 60px;
    background-color: $--color-primary;
    color: $--color-white;
    text-align: center;

    img {
      width: 37px;
      height: 32px;
    }
  }
  .head-right {
    margin-left: 20px;
    height: 60px;

    .icon {
      font-size: 24px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .user-right-box {
    float: right;
    font-size: 0;

    .user-name {
      color: $--color-primary;
      font-size: 14px;
      text-decoration-line: none;
    }
    .tip-txt {
      color: $--color-text-regular;
      font-weight: 400;
      font-size: 14px;

      &::before {
        content: '';
        display: inline-block;
        margin: -3px 10px 0 0;
        width: 20px;
        height: 20px;
        background: url('~@/images/avatar.png') no-repeat 100%;
        vertical-align: middle;
      }
    }
    .vertical-line {
      margin: 0 13px 0 17px;
      color: $--border-color-light;
      font-size: 14px;
    }
    .user-exit {
      margin: 0 16px 0 0;
      cursor: pointer;
      font-size: 14px;

      &::before {
        content: '';
        display: inline-block;
        margin: -2px 6px 0 0;
        width: 20px;
        height: 20px;
        background: url('~@/images/logout.png') no-repeat 100%;
        vertical-align: middle;
      }
    }
  }
}
</style>
