// import { Message } from 'element-ui';
import { imChannel, getChannelList } from '@/api';
import { isArray } from '@/utils/util';

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
    channelList: [],
  },
  mutations: {
    save(state, data) {
      if (isArray(data)) {
        data.forEach((element) => {
          state[element.name] = element.value;
        });
      } else {
        state[data.name] = data.value;
      }
    },
  },
  actions: {
    getList({ commit }, params) {
      imChannel.getList(params)
        .then(({ data }) => {
          commit('save', [
            {
              name: 'list',
              value: data && data.data,
            },
            {
              name: 'total',
              value: (data && data.total) || 0,
            },
          ]);
        });
    },
    getChannelList({ commit }, params) {
      getChannelList(params)
        .then((res) => {
          commit('save', {
            name: 'channelList',
            value: res.data.list,
          });
        });
    },
    save(vuex, params) {
      return new Promise((resolve) => {
        imChannel.saveChannelMess(params)
          .then((res) => {
            resolve(res);
          });
      });
    },
  },
};
