import { userInfoApi, question } from '@/api';
import { SET_USERINFO } from './mutation-type';

// 获取用户信息
const fetchUserInfo = ({ commit }) => new Promise((resolve, reject) => {
  userInfoApi()
    .then((res) => {
      if (res) {
        commit(SET_USERINFO, res.data);
      } else {
        commit(SET_USERINFO, {});
      }
      resolve(res);
    })
    .catch(e => reject(e));
});

// 退出
const fetchLoginOut = ({ commit }) => new Promise((resolve) => {
  commit(SET_USERINFO, {});
  sessionStorage.setItem('token', ''); // 清除token
  resolve('');
});

// 退出
const problemOrginList = () => new Promise((resolve) => {
  question.originList().then((res) => {
    resolve(res.data);
  });
});

const getOriginList = (store, params) => new Promise((resolve) => {
  if (!params.channelCode) {
    resolve([]);
    return;
  }
  question.getOriginList(params).then((res) => {
    resolve(res.data);
  });
});

const classificaPageList = () => new Promise((resolve) => {
  question.classificaPageList().then((res) => {
    resolve(res.data.data);
  });
});

export default {
  fetchUserInfo,
  fetchLoginOut,
  problemOrginList,
  classificaPageList,
  getOriginList,
};
