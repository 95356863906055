import {
  live,
} from '@/api';

import { isArray } from '@/utils/util';


export default {
  namespaced: true,
  state: {
    // roomId: '',
    liveInfo: {},
    roomStatus: false,
    orderId: '', // 聊天使用的额外参数，没有意义
    userList: [],
    allUserIds: [],
    activeUserCount: 0,
    liveTime: 0, // 直播时长
    allUserTimes: 0, // 人次
    allUserCount: 0, // 人数
  },
  mutations: {
    save(state, data) {
      if (isArray(data)) {
        data.forEach((element) => {
          state[element.name] = element.value;
        });
      } else {
        state[data.name] = data.value;
      }
    },
    clearUser(state) {
      state.userList = [];
      state.activeUserCount = 0;
      state.allUserIds = [];
      state.allUserTimes = 0;
      state.allUserCount = 0;
    },
  },
  actions: {
    // 创建房间
    createRoom({ commit }, params) {
      return new Promise((reslove) => {
        live.createLiveRoom(params)
          .then((res) => {
            commit('save', [
              {
                name: 'liveInfo',
                value: res.data || {},
              },
            ]);
            reslove(res);
          });
      });
    },
    userChange({ state, commit }, params) {
      const {
        userList, allUserIds, allUserTimes, allUserCount,
      } = state;
      let userCount = allUserCount;

      if (isArray(params)) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < params.length; index++) {
          const element = params[index];
          if (allUserIds.indexOf(element) === -1) {
            userList.push({
              fromUserId: element,
              isDisabled: false,
            });
            userCount += 1;
            allUserIds.push(element);
          }
        }

        commit('save', [
          {
            name: 'activeUserCount',
            value: userList.length,
          },
          {
            name: 'userList',
            value: userList,
          },
          {
            name: 'allUserTimes',
            value: allUserTimes + params.length,
          },
          {
            name: 'allUserCount',
            value: userCount,
          },
          {
            name: 'allUserIds',
            value: allUserIds,
          },
        ]);

        return;
      }


      if (params.msgType === 601) { // 有人加入了房间
        userList.push({
          isDisabled: false,
          ...params,
        });
        if (allUserIds.indexOf(params.fromUserId) === -1) {
          userCount += 1;
          allUserIds.push(params.fromUserId);
        }
        commit('save', [
          {
            name: 'activeUserCount',
            value: params.msgContent.msg,
          },
          {
            name: 'userList',
            value: userList,
          },
          {
            name: 'allUserTimes',
            value: allUserTimes + 1,
          },
          {
            name: 'allUserCount',
            value: userCount,
          },
          {
            name: 'allUserIds',
            value: allUserIds,
          },
        ]);
      }

      if (params.msgType === 602) { // 有人退出了房间
        const newList = userList.filter(item => item.fromUserId !== params.fromUserId);
        commit('save', [
          {
            name: 'activeUserCount',
            value: params.msgContent.msg,
          },
          {
            name: 'userList',
            value: newList,
          },
        ]);
      }
    },
    setUserState({ state, commit }, user) {
      const { userList } = state;
      userList.map((item) => {
        if (item.fromUserId === user.fromUserId) {
          return user;
        }
        return item;
      });
      commit('save', [
        {
          name: 'userList',
          value: userList,
        },
      ]);
    },
  },
};
