export default {
  loginUrl: 'https://staff.medbanks.cn/login',
  // baseUrl: 'https://gateway.ytbxjj.com',
  baseUrl: 'https://api-gateway.medbanks.cn',
  wsUrl: 'wss://api-gateway.medbanks.cn/ws/connect',
  h5Url: 'http://mservice.medbanks.cn',

  /** @name 知识库-模版管理 */
  h5TemplateUrl: 'https://m.ytbxjj.com/activities/activities-H5/qaLanding?templateId=',
};
