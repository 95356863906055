export default {
  responseProps: {
    code: 'code', // 响应状态码
    data: 'data', // 响应数据
    message: 'message', // 响应文案信息
  },
  responseListProps: {
    results: 'data', // 响应数组数据
    pageSize: 'pageSize', // 页长
    pageNumber: 'pageNum', // 页码
    totalCount: 'total', // 页面数据总量
    cursorCode: 'cursorCode', // 游标标记字段
  },
  // 文件响应数据键值
  responseFileProps: {
    fileId: 'id', // 文件标识字段
    fileUrl: 'fileUrl', // 文件地址
    fileName: 'fileName', // 文件名
    fileSuffix: 'suffix', // 文件后缀
  },
};
